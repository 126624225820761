import React from "react";
import "../Styles/Footer.css";
import { Link } from "react-router-dom";

function Footer() {
  return (
    <div className="footer-section">
      <div className="footer-container">
        <div className="ft-info">
          <div className="ft-info-p1">
            <p className="ft-title">
              Monzo <span className="ft-sign"></span>
            </p>
            <p className="ft-description">
            Our technology offers a suite of solutions that transform your living space into a hub of convenience and security. It seamlessly integrates advanced home automation to manage your everyday tasks effortlessly, from adjusting lighting and temperature to securing doors and windows. 
            </p>
          </div>
        </div>

       

        <div className="ft-list">
          <p className="ft-list-title">Legal</p>
          <ul className="ft-list-items">
            <li>
              <Link to={"/Terms"}>Terms and conditions</Link>
            </li>
            <li>
              <Link to={"/Privacy"}>Privacy Policy</Link>
            </li>
            <li>
              <Link to={"/Refund"}>Cancellation and refund</Link>
            </li>
           
          </ul>
        </div>

        <div className="ft-list" id="contact">
          <p className="ft-list-title">Talk To Us</p>
          <ul className="ft-list-items">
           
            <li>
              <a href="mailto:contact@monzo.estate">contact@monzo.estate</a>
            </li>
         
          </ul>
        </div>
      </div>

      <div className="ft-copyright">
        <p>© 2013-2023 M`onzo. All rights reserved.</p>

        <ul className="ft-social-links">
          <li>
            
            <a href="https://www.instagram.com/monzo.management" title="instagram" target="_blank" rel="">
            <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 15 15" ><path fill="none" stroke="currentColor" d="M11 3.5h1M4.5.5h6a4 4 0 0 1 4 4v6a4 4 0 0 1-4 4h-6a4 4 0 0 1-4-4v-6a4 4 0 0 1 4-4Zm3 10a3 3 0 1 1 0-6a3 3 0 0 1 0 6Z"/></svg>
          
             
            
            </a>
          </li>



          <li>
            <a href="https://wellfound.com/l/2An7d8" title="Wellfoung" target="_blank" rel="">
            <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24"><path fill="currentColor" d="M23.998 8.128c.063-1.379-1.612-2.376-2.795-1.664c-1.23.598-1.322 2.52-.156 3.234c1.2.862 2.995-.09 2.951-1.57m0 7.748c.063-1.38-1.612-2.377-2.795-1.665c-1.23.598-1.322 2.52-.156 3.234c1.2.863 2.995-.09 2.951-1.57zm-20.5 1.762L0 6.364h3.257l2.066 8.106l2.245-8.106h3.267l2.244 8.106l2.065-8.106h3.257l-3.54 11.274H11.39q-1.096-4.07-2.188-8.14l-2.233 8.14H3.5z"/></svg>
             
            </a>
          </li>

      
        </ul>
      </div>
    </div>
  );
}

export default Footer;
