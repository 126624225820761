import React, { useState } from "react";
import "../Styles/Contact.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function Contact() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [contactNumber, setContactNumber] = useState("");
  const [message, setMessage] = useState("");
  const [formErrors, setFormErrors] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    switch (name) {
      case "name":
        setName(value);
        break;
      case "email":
        setEmail(value);
        break;
      case "contactNumber":
        setContactNumber(value);
        break;
      case "message":
        setMessage(value);
        break;
      default:
        break;
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const errors = {};

    if (!name.trim()) errors.name = "Name is required";
    if (!email.trim()) errors.email = "Email is required";
    else if (!/\S+@\S+\.\S+/.test(email))
      errors.email = "Email address is invalid";
    if (!contactNumber.trim())
      errors.contactNumber = "Contact number is required";
    else if (contactNumber.trim().length !== 10)
      errors.contactNumber = "Contact number must be of 10 digits";
    if (!message.trim()) errors.message = "Message is required";

    if (Object.keys(errors).length > 0) {
      setFormErrors(errors);
      return;
    }

    setName("");
    setEmail("");
    setContactNumber("");
    setMessage("");
    setFormErrors({});

    const toastPosition = toast.POSITION?.TOP_CENTER || "top-center";
    toast.success("Information Submitted!", { position: toastPosition });
  };

  return (
    <div className="contact-section" id="Contact-us">
      <div className="c-title-content">
        <h3 className="c-title">
          <span>Contact us</span>
        </h3>
      </div>
      <form
        onSubmit={handleSubmit}
        className="contact-form"
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
            marginBottom: "20px",
          }}
        >
          <div style={{ flex: 1, marginRight: "20px" }}>
            {" "}
            {/* Column for inputs */}
            <input
              type="text"
              name="name"
              placeholder="Your Name"
              value={name}
              onChange={handleChange}
              required
              style={{ display: "block", width: "100%", marginBottom: "10px" }}
            />
            {formErrors.name && (
              <p className="error-message">{formErrors.name}</p>
            )}
            <input
              type="email"
              name="email"
              placeholder="Your Email"
              value={email}
              onChange={handleChange}
              required
              style={{ display: "block", width: "100%", marginBottom: "10px" }}
            />
            {formErrors.email && (
              <p className="error-message">{formErrors.email}</p>
            )}
            <input
              type="text"
              name="contactNumber"
              placeholder="Your Contact Number"
              value={contactNumber}
              onChange={handleChange}
              required
              style={{ display: "block", width: "100%", marginBottom: "10px" }}
            />
            {formErrors.contactNumber && (
              <p className="error-message">{formErrors.contactNumber}</p>
            )}
          </div>
          <div style={{ flex: 2 }}>
            {" "}
            {/* Column for textarea */}
            <textarea
              name="message"
              placeholder="Your Message"
              value={message}
              onChange={handleChange}
              required
              style={{ width: "100%", height: "140px" }}
            />
            {formErrors.message && (
              <p className="error-message">{formErrors.message}</p>
            )}
          </div>
        </div>
        <button
          type="submit"
          style={{
            width: "150px",
            height: "40px",
            fontSize: "16px",
            backgroundColor: "#007BFF",
            color: "white",
            border: "none",
            borderRadius: "5px",
            cursor: "pointer",
            marginBottom: "20px"
          }}
        >
          Send
        </button>
      </form>
      <ToastContainer autoClose={5000} limit={1} closeButton={false} />
    </div>
  );
}

export default Contact;
