import React from "react";
import InformationCard from "./InformationCard";

import "../Styles/Info.css";

function Info() {
  return (
    <div className="info-section" id="services">
      <div className="info-title-content">
        <h3 className="info-title">
          <span>What We Do</span>
        </h3>
        <p className="info-description">
          Our technology is designed to subtly enhance your daily life, giving
          you more time to savor those special moments—like waking up to the
          aroma of your favorite breakfast or feeling the gentle evening breeze.
          It diligently monitors your spaces, ensuring safety and peace of mind,
          so you can focus on enjoying life's pleasures without any
          distractions. By blending into the background, our tech allows you to
          live fully and freely, just as you are meant to.
        </p>
      </div>

      <div className="info-cards-content">
        <InformationCard
          title="For the RWA"
          description="

For the RWA
All things RWA, digitalised and smarter. From ERP & accounting to amenity booking, packed with over 250 features, the Monzo Estate app is the world’s most comprehensive community management platform."
        />

        <InformationCard
          title="For residents"
          description="
Monzo Estate is used by over 4 million families for everything from the visitor management system, to the local services directory, to a home planner, and everything in between. Monzo Estate is for home, and all that’s around."
        />

        <InformationCard
          title="
For brands"
          description="
The world’s best brands engage our audience via the Monzo Estate Ad Platform, which provides 360-degree campaign solutions to bring new products and services alive in the nation’s consumption hotspots."
        />
      </div>
    </div>
  );
}

export default Info;
