import React from "react";


function termconditions() {
 

  return (

    <div style={{ padding: '35px' }} >
    <h1 style={{ padding: '35px', backgroundColor: '#f2f2f2', borderRadius: '8px' }}>Term Conditions for MONZO GROUPS:</h1>
    <p style={{ padding: '35px', backgroundColor: '#f9f9f9', borderRadius: '5px' }} >
       At MONZO GROUPS, we are committed to protecting the privacy and personal information of our users. This Privacy Policy outlines how we collect, use, store, and disclose information when you use our website and services. By accessing or using the MONZO GROUPS website or services, you agree to the terms and practices described in this policy.
 
       <strong>Information We Collect:</strong>
       <ul>
          <li><strong>Personal Information:</strong> We may collect personal information, such as your name, email address, contact information, and any other information you voluntarily provide to us.</li>
          <li><strong>Non-Personal Information:</strong> We may collect non-personal information, such as your device information, browser type, IP address, and usage data. This information is collected through cookies and similar technologies.</li>
       </ul>
 
       <strong>Use of Information:</strong>
       <ul>
          <li>We may use the information collected to provide, maintain, and improve our website and services.</li>
          <li>Personal information may be used to communicate with you, respond to inquiries, and provide you with relevant information.</li>
          <li>Non-personal information may be used for analytics, research, and to enhance the user experience on our website.</li>
       </ul>
 
       <strong>Data Storage and Security:</strong>
       <ul>
          <li>We take reasonable measures to protect the security and integrity of your personal information. However, no data transmission over the internet or electronic storage system is entirely secure. We cannot guarantee absolute security, and you provide information at your own risk.</li>
          <li>We store and process data on secure servers and have implemented appropriate technical and organisational measures to safeguard your information.</li>
          <li>You can request your account and associated data be deleted from the registered email address via sending an email to <a href="mailto:contact@monzo.estate">contact@monzo.estate</a></li>
       </ul>
 
       <strong>Third-Party Disclosure:</strong>
       <ul>
          <li>We do not sell, trade, or transfer your personal information to third parties without your consent, except as required by law or to fulfil our services.</li>
          <li>We may share non-personal information with third parties for analytics, research, or marketing purposes.</li>
       </ul>
 
       <strong>Cookies and Tracking Technologies:</strong>
       <ul>
          <li>We use cookies and similar tracking technologies to enhance the user experience and collect non-personal information.</li>
          <li>You may choose to disable cookies through your browser settings. However, this may affect certain features or functionality of our website.</li>
       </ul>
 
       <strong>External Links:</strong> Our website and services may contain links to third-party websites. We are not responsible for the privacy practices or content of these external sites. We encourage you to review their privacy policies before providing any personal information.
 
       <strong>Children's Privacy:</strong> Our website and services are not intended for individuals under the age of 18. We do not knowingly collect personal information from children. If we become aware that we have inadvertently collected personal information from a child under 18, we will take immediate steps to delete it.
 
       <strong>Updates to Privacy Policy:</strong> We may update this Privacy Policy from time to time to reflect changes in our practices or applicable laws. The updated version will be posted on our website with the effective date.
 
       <strong>Contact Us:</strong>
       If you have any questions, concerns, or requests regarding this Privacy Policy, please contact us at <a href="mailto:contact@monzo.estate">contact@monzo.estate</a>
 
       By using the MONZO GROUPS website or services, you acknowledge that you have read and understood this Privacy Policy and consent to the collection, use, and disclosure of your information as described herein.
 
       Thank you for trusting MONZO GROUPS with your privacy.
    </p>
 </div>

  );
}

export default termconditions;
