import React from "react";
import real_state1 from "../Assets/Buying-Real-Estate-removebg-preview.png";
import "../Styles/About.css";

function About() {
  return (
    <div className="about-section" id="about">
      <div className="about-image-content">
       <img src={real_state1} alt="real_state1" className="about-image1" />
      </div>

      <div className="about-text-content">
        <h3 className="about-title">
          <span>About Us</span>
        </h3>
        <p className="about-description">
          Our technology offers a suite of solutions that transform your living
          space into a hub of convenience and security. It seamlessly integrates
          advanced home automation to manage your everyday tasks effortlessly,
          from adjusting lighting and temperature to securing doors and windows.
          This not only enhances your comfort but also ensures your safety with
          state-of-the-art security systems that keep watch over your home
          around the clock.
          <br />
          <br />
          Our technology offers a suite of solutions that transform your living
          space into a hub of convenience and security. It seamlessly integrates
          advanced home automation to manage your everyday tasks effortlessly,
          from adjusting lighting and temperature to securing doors and windows.
          This not only enhances your comfort but also ensures your safety with
          state-of-the-art security systems that keep watch over your home
          around the clock.
        </p>
      </div>
    </div>
  );
}

export default About;
