import React from "react";
import real_state2 from "../Assets/Privacy-policy-01-removebg-preview.png";
import "../Styles/Privacy.css";

function Privacy() {
  return (
    <div className="p-section" id="Privacy">
      <div className="p-image-content">
        <img src={real_state2} alt="real_state2" className="ba-image1" />
      </div>

      <div className="p-text-content">
        <h3 className="p-title">
          <span>Privacy policy</span>
        </h3>
        <p className="p-description">
          Our Free Privacy Policy Generator can help you comply with CCPA, CPRA,
          GDPR plus with the requirements of Google Analytics & AdSense and many
          others. Generate a Free Privacy Policy for your site and for your app.
          Having a standard privacy policy is an essential part of running your
          online business, as it helps you outline how you process personal
          data, what your customers can expect, and how you deal with third
          party services. A privacy policy also assists in complying with
          federal laws like the General Data Protection Regulation (GDPR) and
          the California Consumer Privacy Act (CCPA). However, a privacy policy
          isn't always given the attention it deserves. In this article, we’ll
          share some of the best privacy policy examples from leading
          businesses, SaaS companies, and eCommerce stores to help you create
          your own or to use it as a personal swipe file. The examples offer a
          window into being able to legally collect personal data under the
          relevant electronics document act of your place of business. Later
          down the post, we also include a free privacy policy template that's
          yours to download, edit, and tweak to your requirements. This template
          includes details on how to manage personal information, highlight how
          data is shared with third party services, and inform users of security
          measures.
        </p>
      </div>
    </div>
  );
}

export default Privacy;
