import React from "react";


function refund() {
 

  return (

<div style={{ padding: '35px', backgroundColor: '#f4f4f4', borderRadius: '10px', boxShadow: '0 4px 8px rgba(0,0,0,0.1)', margin: '20px' }}>
    <h1 style={{ padding: '35px', backgroundColor: '#f2f2f2', borderRadius: '8px', textAlign: 'center', marginBottom: '20px' }}>Refund Policy of Monzo GROUPS</h1>
    <p style={{ padding: '35px', fontSize: '16px', lineHeight: '1.6', fontFamily: 'Arial, sans-serif' }}>
        Thanks for your interest in Monzo GROUPS. We ensure to provide an excellent experience and learning to all our users. As with any online purchase experience, some terms and conditions govern the Refund Policy. When you purchase a program at Saral Groups, you agree to our Terms & Conditions and refund policy.

        <strong>Our refund policy is as follows:</strong>

        <ul>
            <li>You acknowledge that the Services purchased by you are non-transferable and non-refundable.</li>
            <li>You acknowledge that we are under no obligation to refund any fees and applicable charges paid by you, in full or partially, under no circumstances, including for modifying and extending the duration of the Service, change in the commencement date of the Service, your failure to attend or participate in the Service, or modification of the structure or content of the Service.</li>
            <li>If a refund is provided, you hereby agree and acknowledge that such amount to be refunded may either be paid in cash or credit (for purchase of any other Services of Monzo Groups of equivalent value) at the sole discretion of Monzo School.</li>
        </ul>
    </p>
</div>


  );
}

export default refund;
