import React, { useEffect, useState } from "react";
import real_state from "../Assets/real_state-removebg-preview.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleUp } from "@fortawesome/free-solid-svg-icons";
import "../Styles/Main.css";

function Main() {
  // const navigate = useNavigate();
  const [goUp, setGoUp] = useState(false);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  useEffect(() => {
    const onPageScroll = () => {
      if (window.scrollY > 600) {
        setGoUp(true);
      } else {
        setGoUp(false);
      }
    };
    window.addEventListener("scroll", onPageScroll);

    return () => {
      window.removeEventListener("scroll", onPageScroll);
    };
  }, []);

  return (
    <div className="section-container">
      <div className="hero-section">
        <div className="text-section">
          <p className="text-headline">❤️ Home comes first</p>
          <h2 className="text-title">
            Ordinary Experiences, Elevated to Excellence
          </h2>
          <p className="text-descritpion">
            Innovative solutions for seamless home management, robust security,
            and enhanced community interaction.
          </p>
        
          <div className="text-stats">
            <div className="text-stats-container">
              <p>145k+</p>
              <p>Communities</p>
            </div>

            <div className="text-stats-container">
              <p>50+</p>
              <p>Major cities</p>
            </div>

            <div className="text-stats-container">
              <p>6mn+</p>
              <p>Families</p>
            </div>
          </div>
        </div>

        <div className="hero-image-section">
          <img className="hero-image1" src={real_state} alt="real_state" />
        </div>
      </div>

      <div
        onClick={scrollToTop}
        className={`scroll-up ${goUp ? "show-scroll" : ""}`}
      >
        <FontAwesomeIcon icon={faAngleUp} />
      </div>
    </div>
  );
}

export default Main;
